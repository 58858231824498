;(function ( $ ) {

  var popupConfigArray = [],
      hrefList = [];

  $.fn.lightbox = function()
  {
    if (!this.length) { return; }

    this.each(function() {
      var $this = $(this);

      config = popupConfigFor($this);
      $this.magnificPopup(config);
    });
  };

  function popupConfigFor(elt) {
    var popupType = elt.data('popup-type'),
        popupSrc = elt.attr('href');

    switch(popupType) {
      case 'ajax':
        config = {
          fixedContentPos: false,
          fixedBgPos: true,
          overflowY: 'auto',
          preloader: false,
          midClick: true,
          removalDelay: 300,
          mainClass: 'mfp-details',
          items: {
            src: popupSrc,
            type: 'ajax'
          },
          callbacks: {
            ajaxContentAdded: _reinit
          }
        };
        break;
      case 'modal':
        config = {
          fixedContentPos: false,
          fixedBgPos: true,
          modal: true,
          overflowY: 'auto',
          preloader: false,
          midClick: true,
          removalDelay: 300,
          mainClass: 'mfp-details',
          items: {
            src: popupSrc,
            type: 'ajax'
          },
          callbacks: {
            ajaxContentAdded: _loadContact
          }
        };
        break;
      case 'inpage':
        config = {
          fixedContentPos: false,
          fixedBgPos: true,
          overflowY: 'auto',
          preloader: false,
          midClick: true,
          removalDelay: 300,
          mainClass: 'mfp-details',
          items: {
            src: popupSrc,
            type: 'inline'
          }
        };
        break;
      // case 'gallery-image':
      //   config = {
      //     fixedContentPos: false,
      //     fixedBgPos: true,
      //     overflowY: 'auto',
      //     preloader: false,
      //     midClick: true,
      //     removalDelay: 300,
      //     mainClass: 'mfp-purchase',
      //     items: {
      //       src: popupSrc,
      //       type: 'inline'
      //     },
      //   };
      //  break;
    }
    return config;
  }

  function _reinit() {
    // the script binds on page load, so we need to rebind
    // to the new HTML when the ajax call finishes
    // but we don't need to rebind all of them
    $('.mfp-content').find('[data-popup-type]').each(function() {
      $(this).lightbox();
    });
  }

  function _loadContact() {
    // the script binds on page load, so we need to call
    // the contact script here instead of in the main application.js
    $('.mfp-content').find('form').each(function() {
      $(this).contactForm();
    });
  }

}( jQuery ));