;(function($) {
  const body_el = $('body');

  let thumbs_per_page = 5,
      img_count = 0,
      current_img = 0,
      current_thumb_page = 0,
      gallery_el,
      thumbs_el;

  const arrow_left = '<svg viewBox="0 0 100 100" class="icon navigate-before"><use xlink:href="mrp.svg#navigate-before"></use></svg>',
        arrow_right = '<svg viewBox="0 0 100 100" class="icon navigate-next"><use xlink:href="mrp.svg#navigate-next"></use></svg>';

  // Compute and return thumbnail page for the current image
  function getThumbPageCurrentImg() {
    return Math.floor(current_img / thumbs_per_page);
  }


  // Go to the sellected image
  function goToImg(img_number) {
    let thumb_page;

    // go to the last image
    if ( img_number == -1 ) {
      img_number = img_count - 1;
    }

    // go to the first image
    if ( img_number == img_count ) {
      img_number = 0;
    }

    // change which image is visible
    gallery_el.find('[data-img-number="' + img_number + '"]').addClass('is-active').siblings('.is-active').removeClass('is-active');
    // change which thumbnail is selected
    thumbs_el.find('[data-img-number="' + img_number + '"]').addClass('is-active').siblings('.is-active').removeClass('is-active');

    // if necessary go to the correct thumbnail page
    current_img = img_number;
    thumb_page = getThumbPageCurrentImg();
    if ( thumb_page !== current_thumb_page ) {
      goToThumbPage(thumb_page);
    }

    //var imgId = img_number;
    //var href = '' + galName + '/' + imgId;
    //history.pushState({'galName': galName, 'imgId': img_number }, '', href);
  }


  // Go to the selected thumbnail page
  function goToThumbPage(thumb_page_number) {
    let min_thumb, max_thumb,
        max_page_numb = Math.ceil(img_count / thumbs_per_page);

    if ( thumb_page_number === -1 ) {
      thumb_page_number = max_page_numb - 1;
    }

    if ( thumb_page_number === max_page_numb ) {
      thumb_page_number = 0;
    }

    min_thumb = ((thumb_page_number + 1) * thumbs_per_page) - thumbs_per_page;
    max_thumb = (thumb_page_number + 1) * thumbs_per_page - 1;
    current_thumb_page = thumb_page_number;
    display_page_numb = (thumb_page_number + 1);
    thumbs_el.children().map(function(i) {
      // this class has order:2 & is hidden to emulate paging
      if ( i >= min_thumb && i <= max_thumb ) {
        $(this).removeClass('offscreen');
      } else {
        $(this).addClass('offscreen');
      }
    });

    // display current page
    if ( $('.pagination').length == 1 ) {
      $('.pagination').empty().html(display_page_numb + ' / ' + max_page_numb);
    } else {
      $('<p/>', {
        'class': 'pagination',
        html: display_page_numb + ' / ' + max_page_numb
      }).insertAfter(thumbs_el);
    }
  }


  // Get the direction of travel
  // next: 1
  // previous: -1
  function getDirection(arrow_clicked) {
    let direction;
    if ( arrow_clicked === 'next' ) {
      direction = 1;
    } else {
      direction = -1;
    }
    return direction;
  }


  // Use if the pagination needs to be linked.
  // call to add <li>s for pagination
  // function pagination() {
  //   var max_page_numb = Math.ceil(img_count / thumbs_per_page);
  //   $('<ul/>', {
  //     'class': 'pagination',
  //     html:  function(n) {
  //       while (n < max_page_numb) {
  //         n++;
  //         $(this).append('<li/>');
  //       }
  //     }
  //   }).insertAfter(thumbs_el);
  // }


  // Resize the gallery images based on the browser size
  function resizeGallery() {
    let gal_imgs = gallery_el.find('img'),
        window_width = $('body').width(),
        // TODO: this window.height call occasionally fails in Firefox...
        window_height = $(window).height(),
        size_w = Math.ceil(gallery_el.innerWidth() - 20),
        size_h = Math.ceil(window_height - 20);
        // the "- 20" gives the image breathing room at top and bottom

    // size the thumbs to whichever is smaller so it's completely visible
    gal_imgs.each(function() {
      if ( window_width < 800 || window_height < 800 ) {
          $(this).css('max-height', size_h);
      } else {
        if ( size_w < size_h ) {
          $(this).css('max-height', size_w);
        } else {
          $(this).css('max-height', size_h);
        }
      }
    });
  }


  // Resize the thumbnails based on the browser size
  // wait for the image to finish loading then set the height of the parent <li>
  // the correct width can not be set until after the image loads!
  function resizeThumbs(thumbs_per_page) {
    let img_el = thumbs_el.find('img'),
        li_el = thumbs_el.find('li');

    $(img_el).on('load', function() {
      let width = li_el.width();
      $(this).parent().css('max-height', width);
    });
  }


  // TODO: setThumbsByWindowWidth() needs review because:
  // Set the number of thumbnails by window size
  // resize the thumbnails - resizeThumbs()
  // show correct thumbnail after resize by calling goToThumbPage()
  function setThumbsByWindowWidth() {
    let thumb_page,
        window_width = $('body').width();

    // TODO: this doesn't ACTUALLY CONTROL the number of thumbnails.
    //   CSS CONTROLS # THUMBS PER PAGE!
    //   so, if the css changed even a little, THIS WOULD BREAK.
    //   this needs to be rewritten to get actual visible items,
    //   or to actually control the number visible
    //
    if ( window_width >= 320 && window_width < 480 ) {
      thumbs_per_page = 2;
    } else
    if ( window_width >= 480 && window_width < 800 ) {
      thumbs_per_page = 3;
    } else
    if ( window_width >= 800 && window_width < 1024 ) {
      thumbs_per_page = 4;
    } else
    if ( window_width >= 1024 && window_width < 1280 ) {
      thumbs_per_page = 5;
    } else
    if ( window_width >= 1280 && window_width < 1920 ) {
      thumbs_per_page = 6;
    } else
    if ( window_width >= 1920 ) {
      thumbs_per_page = 9;
    }

    resizeThumbs(thumbs_per_page);
    thumb_page = getThumbPageCurrentImg();
    goToThumbPage(thumb_page);
    // pagination(thumbs_per_page);
  }


  // Get json for the specified gallery
  // On done build gallery
  $.fn.carousel = function(options) {
    if ( options.img_number !== null ) {
      current_img = options.img_number;
    }
    $.ajax({
      type: 'GET',
      url: 'ajax/' + options.gallery_name + '.json',
      dataType: 'json'
    }).then(function(data) {
      let gallery_content,
          main_el = $('main'),
          board_el = ($('.board').length !== 0) ? $('.board') : $('<div class="board" />'),
          items = $.map(data, function(obj, i) {
            let the_classes;
            if ( i != current_img ) {
              the_classes = 'carousel-seat';
            } else {
              the_classes = 'carousel-seat is-active';
            }
            return '<li class="' + the_classes + '" data-img-number="' + i + '"><img alt="' + obj.title + '" src="' + obj.src + '"></li>';
          });

      if ( main_el.has(board_el).length > 0 ) {
        board_el.empty();
      } else {
        main_el.append(board_el);
      }

      $('<ul/>', {
        'class': 'carousel gallery',
        html: items.join('')
      }).appendTo(board_el).wrapAll('<div />');

      $('<div/>', {
        'class': 'controls',
        html: '<a class="toggle" data-toggle="prev">' + arrow_left + '</a><a class="toggle" data-toggle="next">' + arrow_right + '</a>'
      }).insertAfter('.carousel');

      gallery_el = $('.carousel.gallery');
      img_count = gallery_el.find('img').length;

      // Clone the gallery to create the thumbnails and append to the page
      gallery_content = gallery_el.parent().clone();
      gallery_el.parents('.board').append(gallery_content);
      board_el.children('div:last-child').children().addClass('thumbs').removeClass('gallery');

      thumbs_el = $('.carousel.thumbs');

      // Rename the buttons cloned for the thumbs nav
      thumbs_el.find('.toggle').each(function() {
        $(this).attr('class', 'toggle-page');
      });

      // Change image and set thumb on gallery arrow nav click
      gallery_el.next().on('click', '.toggle', function(e) {
        goToImg(parseInt(gallery_el.find('.is-active').data('img-number'), 10) + getDirection($(this).data('toggle')));
      });

      // Change thumb page on thumbnail arrow nav click
      thumbs_el.next().on('click', '.toggle', function(e) {
        goToThumbPage(current_thumb_page + getDirection($(this).data('toggle')));
      });

      // this one changes the gallery & thumb is-active when a thumb is clicked
      // (this listens to both thumb and gallery li clicks)
      thumbs_el.on('click', '.carousel-seat', function(e) {
        goToImg(parseInt($(this).data('img-number'), 10));
      });
      // end $(this) for .carousel-seat

    }).done(function() {
      // so, research says, .done() fires when .get() succeeds, and is asynch from there.
      // which means that all the following functions run immediately on success, not after the DOM is complete.
      // but we want these following functions to only fire after all the DOM changes have finished.
      // and jQuery promises are the worst. so:
      // TODO: rewrite ajax call without jQuery!
      // https://blog.garstasio.com/you-dont-need-jquery/ajax/

      // Set up listener for window resize and run setThumbsByWindowWidth
      $(window).resize(function() {
        setThumbsByWindowWidth();
        resizeGallery();
      });
      // mark the current selection "active" in the menu
      // TODO: get the "data-content-type" into options
      $('a[data-gallery-type = "' + options.gallery_name + '"]').addClass('active').siblings().each(function(){if( $(this).hasClass('active')) $(this).removeClass('active');});
      // run other functions
      setThumbsByWindowWidth();
      resizeGallery();
      // run optional function if it has been defined
      if (typeof whenComplete == 'function') {
        whenComplete();
      }

      // Use if the pagination needs to be linked.
      // Note this is unfinished, if it's deemed necessary.
      // pages_el = $('.pagination');
      // pages_el.on('click', 'li', function(e) {
      //   goToThumbPage();
      // });

    }).fail(function() {
      body_el.append('<p>Oh no, something went wrong! Please try reloading the page.</p>');
    });
  };


/*

  // history attempt below

  var loadPage = function(href) {
    //$main.load(href + ' main>*', ajaxLoad);
    console.log(href);
  };


  $(window).on('popstate', function(e) {
    if (e.originalEvent.state !== null) {
      loadPage(location.href);
    }
   });

  let defaultTitle = '';

  window.addEventListener('popstate', function(e) {
    let galleryState = e.state;

    if (galleryState == null) {
      //removeCurrentClass();
      //textWrapper.innerHTML = '';
      //content.innerHTML = '';
      document.title = defaultTitle;
    } else {
      ajaxReq(galName);
      current_img = __?__;
      current_thumb_page
      document.title =  "Mike Richards Photography | " + galName;
    }
  });

  ajaxReq = function ajaxGet(url) {
    return new Promise(function (resolve, reject) {
      req = new XMLHttpRequest();
      req.open("GET", url);
      req.overrideMimeType("application/json");
      req.onload = function () {
        if (req.status === 200) {
          resolve(req.response);
        } else {
          reject(new Error(req.statusText));
        }
      };

      req.onerror = function () {
        reject(new Error("Network error"));
      };

      req.send();
    });
   };

  parseAjax = function parseAjax(res) {
    return res.catch(function (error) {
      throw new AJAXError(error);
    }).then(JSON.parse).catch(function (error) {
      throw new JSONError(error);
    }).then(function (res) {
    })
    .catch(function (error) {

    //throw new ApplicationError(error);
    });
   };

*/

})(jQuery);