jQuery.noConflict();
;(function($) {

// ------------------------------------------------------------------------------------
// global vars
  var isMobile; // tracker variable set by rubberband

  const
    mobile_bp = 800,
    $window       = $(window),
    $body         = $('body'),
    $menu         = $('.c-mainmenu'),
    $menu_btn     = $('.menu-buttons');

// ------------------------------------------------------------------------------------
// BEGIN: random onload items

  // this script enables external svg support in IE.
  svg4everybody();

  $('[data-popup-type]').lightbox();

// END onload items

// ------------------------------------------------------------------------------------
// BEGIN: Galleries
// TODO: add a "loading" graphic

  // build a gallery from user click
  $('.l-gallery').on('click', function(e) {
    e.preventDefault();

    let gallery = $(this);

    // pass variables to the carousel function
    $.fn.carousel({
      gallery_name: $(this).attr('data-gallery-type'),
      img_number: 0
    });
  });

  // optional function that runs after the gallery is built
  whenComplete = function whenComplete() {
    if ($window.height < $body.height) $('html,body').animate({scrollTop: 112},'slow');
    $body.removeClass('home');
    $menu.removeClass('open');
  };

// END carousels

// ------------------------------------------------------------------------------------
// BEGIN Main Menu interaction buttons
// main navigation button controls

// LOGIC:

// we need to change classes based on size of menu.
// home never reappears unless the page is reloaded
// (or back button, so the history stuff will have to account for that: add.home, remove other)

// menu is either tiny or full, open or closed.

// when a gallery is clicked, the menu starts tiny (or closed)
// the menu can be enlarged (or opened)
// that's ONE action - one click on a.button TOGGLES .active

// the hamburger appears ON MOBILE
// the enlarge/shrink appear ON DESKTOP

// if a.active && nav:NOT.home menu is OPEN/FULL
// if a:NOT.active && nav:NOT.home menu is CLOSED/TINY

// if a.active && nav.home menu is OPEN
// if a:NOT.active && nav.home menu is CLOSED --> BUT THIS NEVER HAPPENS <--

// css determines the icon BASED on .home & --> BP <--
// js determines toggle .active

// index starts with menu [ nav.home > a ]

// all that for this tiny function:

  $menu_btn.on('click', function() {
    if ( $menu.hasClass('home') ) {
      $(this).removeClass('active').parents('nav').removeClass('open');
    } else {
      $(this).toggleClass('active').parents('nav').toggleClass('open');
    }
  });

// END main menu buttons

// ------------------------------------------------------------------------------------
// BEGIN Rubberband Breakpoints

// init Rubberband
// define breakpoints here - Rubberband will watch for these,
// and execute the function below when passed.

// defined in em -> 50em = is the mobile_bp of 800px

  $window.on('snap', function(e, data) {

    // 800 and above
    if ( data.minWidth >= 50 ) {
      isMobile = false;
      $body.removeClass('small');
      $body.addClass('large');
    }

    // below 800
    else {
      isMobile = true;
      $body.removeClass('large');
      $body.addClass('small');
    }
  });

  $.rubberband({
    // 50 = 800, 80 = 1280
    minWidth: [ 50, 80 ],
    maxWidth: [ 80, 50 ],
    unit: "em"
  });

// END rubberband breakpoints



///////
//
//--- TODO ---//
//
//    Gallery
//        - buttons should be separate, not in div
//        - image needs to be clickable? if not, above is irrelevant
//            - this is only needed if;
//                (a) fotomoto is a thing
//                (b) the image is the target, not a separate button
//
//    History:
//        - Set history state on load gallery
//        - Set history state on image change
//        - Get history state and set image / thumb
//        - Handle url entry, Rewrite? Something like Nginx: rewrite ^(.+)$ /index.html last;
//
//   Additional items
//       -fotomoto will need updating - if we're still using it
//       -google should switch to tag manager - i think he took it out?
//
//    Lazy Load:  https://learn.jquery.com/code-organization/deferreds/examples/
//        - add code
//        - change image tags
//
///////

})(jQuery);