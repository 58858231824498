;(function($) {

  $.fn.contactForm = function(e, theForm) {
    let magnificPopup = $.magnificPopup.instance;
    const myForm = $(this);

    let refresh = $('#capRefresh');

    // bind the variables for the contact form.
    const formOptions = {
        target:        '#formResponse',   // target element(s) to be updated with server response
        beforeSubmit:  clearResponse,
        success:       clearForm
        // use these functions for debugging
        // beforeSubmit:  showRequest,    // pre-submit callback
        // success:       showResponse,   // post-submit callback

        // other available options:
        //url:       url         // override for form's 'action' attribute
        //type:      type        // 'get' or 'post', override for form's 'method' attribute
        //dataType:  null        // 'xml', 'script', or 'json' (expected server response type)
        //clearForm: true        // clear all form fields after successful submit
        //resetForm: true        // reset the form after successful submit

        // $.ajax options can be used here too, for example:
        //timeout:   3000
    };

    // reload the captcha image
    imgRefresh = function() {
      document.getElementById('captcha_code').value.replace(/[^\d]+/g, '');
    };

    // refresh the captcha completely
    // used in multiple events
    clickRefresh = function() {
      document.getElementById('captcha').src = '/captcha.php?' + Math.random();
      document.getElementById('captcha_code').value = '';
      imgRefresh();
    };

    // close the modal on cancel
    $('.cancel').on('click', function() {
      magnificPopup.close();
    });

    // listen for a refresh click at any time
    refresh.click(function(e) {
      e.preventDefault();
      clickRefresh();
    });

    // notify the user about char. remaining as they type
    $('textarea').on('keyup', function() {
      let maxlen = $(this).attr('maxlength'),
          length = $(this).val().length,
          remainder = Math.floor(maxlen - length);
      if ( length > 0 ) $('#textarea_message').text(remainder);
      else $('#textarea_message').text(maxlen);
    });

    // check that the chars entered in the captcha are numbers
    $('#captcha_code').on('keypress', function(event) {
      // only allow numbers
      let allowedChars = /[0-9]/g;
      // get the key as it's pressed
      let key = String.fromCharCode(event.which);
      // console.log(event.keyCode);
      // allow a few extra strokes to be nice
      // 9 is tab, 13 is enter/return, 8 is backspace, 37 is left arrow, 39 is right arrow
      if (event.keyCode == 9 || event.keyCode == 13 || event.keyCode == 8 || event.keyCode == 37 || event.keyCode == 39 || allowedChars.test(key)) return true;
      // otherwise do nothing
      return false;
    });

    // run jQuery.validate on submit
    myForm.validate({
      submitHandler: function(form) {
        // check the captcha - does it match the server string?
        // if not, generate an error message. otherwise, continue.
        if(!form.captcha.value.match(/^\d{5}$/)) {
          $('.c-form-verification').prepend('<label id="captcha_code-error" class="error" for="captcha">Please enter all of the CAPTCHA digits in the box provided</label>');
          form.captcha.focus();
          return false;
        }
        // validates and submits the form
        $(form).ajaxSubmit(formOptions);
      }
    });

    // clear the captcha error message
    // a new one will be generated (if needed) on each "sucessful" submit
    function clearResponse() {
      if ($('#captcha_code-error').length) $('#captcha_code-error').remove();
    }

    // successful submit - show the response
    function clearForm() {
      // make sure there isn't a captcha error message first!
      if (!($('#captcha_code-error').length)) {
        myForm.hide();              // hide the form
        $('#responseClose').show(); // show the close button
        clickRefresh();             // reset the captcha
      } else {
        clickRefresh();
      }
    }

    // commented out because we don't want it in production
    //
    // // pre-submit callback
    // function showRequest(formData, jqForm, options) {
    //     // formData is an array; here we use $.param to convert it to a string to display it
    //     // but the form plugin does this for you automatically when it submits the data
    //     var queryString = $.param(formData);
    //
    //     // jqForm is a jQuery object encapsulating the form element.  To access the
    //     // DOM element for the form do this:
    //     // var formElement = jqForm[0];
    //
    //     alert('About to submit: \n\n' + queryString);
    //
    //     // here we could return false to prevent the form from being submitted;
    //     // returning anything other than false will allow the form submit to continue
    //     return true;
    // }
    //
    // // post-submit callback
    // function showResponse(responseText, statusText, xhr, $form)  {
    //     // for normal html responses, the first argument to the success callback
    //     // is the XMLHttpRequest object's responseText property
    //
    //     // if the ajaxSubmit method was passed an Options Object with the dataType
    //     // property set to 'xml' then the first argument to the success callback
    //     // is the XMLHttpRequest object's responseXML property
    //
    //     // if the ajaxSubmit method was passed an Options Object with the dataType
    //     // property set to 'json' then the first argument to the success callback
    //     // is the json data object returned by the server
    //
    //     alert('status: ' + statusText + '\n\nresponseText: \n' + responseText +
    //         '\n\nThe output div should have already been updated with the responseText.');
    // }

  };
})(jQuery);